<template>
  <div>
    <hero>
      <v-container style="max-width:1260px;">
        <v-row>
          <v-col class="col-12">
            <h1 class="text-h3 white--text  d-flex align-center">
              <strong class="mr-10">{{title}}</strong>
              <v-select
                v-model="contest"
                :items="options"
                label="Võistlus"
                filled
                hide-details
                color="white"
              />
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </hero>
    <v-container style="margin-top:-330px;max-width:1260px;z-index:3;position:relative;">
      <v-row>
        <v-col>
          <v-card elevation="6">
            <v-card-title>Tootlus (%)</v-card-title>
            <top-table
              :items="top"
              :showPosition="true"
              :showType="!isContest"
              :portfolioType.sync="filterPortfolioType"
              :showHistory="true"
              :year="topYear"
            />
            <v-card-actions />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Hero from '../../components/Hero.vue'
import TopTable from '../../components/TopTable.vue'
import { TopMixin } from '../../mixins/top.mixin'

export default {
  mixins: [ TopMixin ],
  components: {
    Hero,
    TopTable
  },
  data () {
    return {
      top: null,
      filterPortfolioType: null
    }
  },
  computed: {
    title () {
      return 'TOP'
    },
  },
  watch: {
    filterPortfolioType () {
      this.loadYearData()
    }
  },
  methods: {
    loadYearData () {
      this.top = null
      this.$http.get('/api/portfolio/top/nav/' + this.contest.contestId, {
        params: {
          portfolioType: this.filterPortfolioType || undefined
        }
      }).then(response => {
        this.top = response.data
      })
    }
  },
  mounted () {
    this.loadYearData()
  }
}
</script>
